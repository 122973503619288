<template>
  <div class="has-nav-bar view-user-setting">
    <van-nav-bar
      title="设置"
      :border="false"
      fixed
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="user-setting-container">
      <van-cell-group>
        <van-cell is-link @click="clearCache">
          <template #title>
            <span class="custom-title">清理缓存</span>
          </template>
          <span v-if="cacheSize > 0">
            {{ fileSize(cacheSize, { base: 10 }) }}
          </span>
        </van-cell>
        <!--        <van-cell is-link :to="{ name: 'userHelp' }">-->
        <!--          <template #title>-->
        <!--            <span class="custom-title">帮助</span>-->
        <!--          </template>-->
        <!--        </van-cell>-->
        <van-cell is-link :to="{ name: 'userAbout' }">
          <template #title>
            <span class="custom-title">关于</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-button
        class="user-index-logout-btn"
        type="danger"
        block
        @click="logoutHandle"
      >
        退出
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  CellGroup,
  Cell,
  Tag,
  Row,
  Col,
  Button,
  NavBar,
  Dialog,
  Toast,
  Icon
} from "vant";
import { mapActions, mapState } from "vuex";
import androidStore from "../../store/modules/androidStore";
import fileSize from "filesize";
export default {
  name: "UserIndex",
  components: {
    [Tag.name]: Tag,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Row.name]: Row
  },
  computed: {
    ...mapState({
      cacheSize: state => state.androidStore.cacheSize
    })
  },
  data() {
    return {
      fileSize
    };
  },
  methods: {
    ...mapActions(["logout", "clearToken"]),
    onClickLeft() {
      this.$router.back();
    },
    logoutHandle() {
      Dialog.confirm({
        message: "确认要退出当前用户吗？"
      }).then(() => {
        this.logout();
      });
    },
    logoutH5Handle() {
      this.clearToken(false);
    },
    messageHandle() {
      this.$router.push({ path: "/user/message" });
    },
    getCache() {
      window.getCacheSize();
    },
    clearCache() {
      window.clearCache();
      window.clearCacheCallBack = () => {
        Toast("清除缓存成功！");
        this.getCache();
      };
    }
  },
  created() {
    this.getCache();
  }
};
</script>
<style lang="scss">
.view-user-setting {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .user-setting-container {
    margin: 40px 52px;
    height: 100%;
    position: relative;
  }
  .van-cell-group {
    .van-cell {
      padding: 13px 0;
      color: #1b1b4e;
      &.van-cell:not(:last-child)::after {
        left: 0;
      }
      .van-cell__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1;
      }
      > .van-icon {
        color: #ebebeb;
      }
    }
    .van-tag {
      margin-left: 10px;
      background-color: #fd687d;
    }
  }
  .user-index-logout-btn {
    margin-top: 40px;
    background-color: $--button-color-cancel;
    border-color: $--button-color-cancel;
    color: #1b0f49;
  }
  &__icon--right {
    position: relative;
    .van-info {
      top: 20px;
    }
  }
}
</style>
